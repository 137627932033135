import Vue from 'vue';
import Router from 'vue-router';
import * as Sentry from '@sentry/browser';
import store from '@/store';
import { DASHBOARD_ROUTE } from '@/constants/legacyRoutes';
import {
  userIdJWT,
  connectionTypeJWT,
  customerCodeJWT,
  locationIdJWT,
  macJWT,
  nasIdJWT,
  isExpiredJWT,
} from '@/utils/DecodeJWT';

import { ACTION_BLANK_SESSION } from '@/store/global';
import { ACTION_REPLACE_PLANS } from '@/store/plan';

Vue.use(Router);

// the following paths still rely on the old UI framework:
const legacyPaths = [
  '/old',
];

// Datadog Browser Logs will log routes listed in the following array
const routesToLog = [
  '/portal-not-found',
  '/not-connected',
  '/error',
];

async function setup(to, from, next) {
  if (store.state.siteInfo.isOutsideNetwork || localStorage.getItem('isOutsideNetwork') === 'true' || !store.state.siteInfo.locationID) {
    if (store.state.siteInfo.planEngine === 'STRIPE') {
      await store.dispatch(ACTION_REPLACE_PLANS);
    }
    return next();
  }

  // reload the page when coming from the old UI
  // this is to avoid conflicts between UI frameworks and their CSS rules
  // TODO remove when the legacy UI is gone
  if (from.matched.some(({ path }) => legacyPaths.includes(path))) {
    location.hash = `#${to.fullPath}`;
    return;
  }

  try {
    // redirect to the legacy portal
    if (store.state.siteInfo.redirectToLegacy) {
      window.location.href = DASHBOARD_ROUTE;
    }

    if (!store.state.siteInfo.enabled && !store.state.siteInfo.isImpersonating) {
      return next({ name: 'denied-portal' });
    }

    try {
      // redirect to the portal when an active sub is present
      if (
        (to.name === 'landing' || to.name === 'select-plan')
        && store.state.siteInfo.hasActiveSubscription
      ) {
        return next({
          path: '/portal',
        });
      }
      return next(true);
    } catch (error) {
      console.error(error);
      // if end up here, then probably the API is down or not reachable by the Stardust proxy
      store.commit(
        'global/replaceError',
        'Oops. Looks like we can\'t talk to the API server. Please contact customer support team',
      );
    }
  } catch (error) {
    // if we end up here, then for some reason we can't retrieve the context from Codefi
    // most likely, there is no corresponding session or the session got lost.
    store.commit(
      'global/replaceError',
      'Oops. Looks like the wrong URL was used to get to this page',
    );
  }
}

function setupPortal(to, from, next) {
  if (!store.state.siteInfo.enabled && !store.state.siteInfo.isImpersonating) {
    return next({ name: 'denied-portal' });
  }

  if (!store.state.siteInfo.isLoggedIn) {
    if (store.getters['siteInfo/isEmailActivationEnabled'] && to.query.status) {
      return next({
        path: '/login',
        query: to.query,
      });
    }

    return next({ path: '/' });
  }

  if (store.state.siteInfo.redirectToLegacy) {
    window.location.href = DASHBOARD_ROUTE;

    return;
  }

  if (!store.state.siteInfo.enabled && !store.state.siteInfo.isImpersonating) {
    return next({ name: 'denied-portal' });
  }

  return next();
}

function beforeEnterLanding(to, from, next) {
  if (store.state.siteInfo.user && store.state.siteInfo.user.paused) {
    return next({ path: '/portal' });
  }

  if (store.state.siteInfo.isImpersonating
    && (store.getters['siteInfo/isAnchor'] || store.getters['siteInfo/isFreeSignUp'] || store.getters['siteInfo/isNAAS'])) {
    return next({ path: '/portal' });
  }

  next(true);
}

function redirectIfNotLoggedIn(to, from, next) {
  return next(!store.state.siteInfo.isLoggedIn ? { path: '/' } : true);
}

function redirectIfNotLoggedInOrIfAnchorFujitsu(to, from, next) {
  if (!store.state.siteInfo.isLoggedIn) {
    next({ path: '/login' });
  }

  if (
    store.getters['siteInfo/isEmailActivationEnabled']
    && store.state.siteInfo.user && !store.state.siteInfo.user.active
  ) {
    next({ path: '/portal' });
  }

  if (
    store.getters['siteInfo/isAnchor']
    && (to.path !== '/portal/device-management' && to.path !== '/portal/preferences-and-privacy' && to.path !== '/portal/my-account' && to.path !== '/portal/my-account/edit')
  ) {
    next({ path: '/portal/device-management' });
  }

  next(true);
}

function setupOutOfNetwork(to, from, next) {
  localStorage.setItem('isOutsideNetwork', 'true');
  return next();
}

function checkIfHasZoneData(to, from, next) {
  return store.dispatch('siteInfo/hasZoneData')
    .then((response) => {
      if (response) {
        return next();
      }

      if (store.state.siteInfo.isLoggedIn) {
        return next({ name: 'activate' });
      }
    })
    .catch(() => next({ name: 'error' }));
}

function getQueryParameter(to, name) {
  return to.query[name] ? to.query[name] : null;
}

async function ensureSession(to) {
  if (to.name !== 'landing-login' && !isExpiredJWT()) {
    return;
  }
  const queryLocationId = getQueryParameter(to, 'location_id');
  let locationId;
  if (!queryLocationId) {
    locationId = locationIdJWT();
  } else {
    locationId = queryLocationId;
  }

  const storedMac = queryLocationId ? getQueryParameter(to, 'mac_id') : macJWT();
  const nasId = queryLocationId ? getQueryParameter(to, 'nas_id') : nasIdJWT();
  const customerCode = queryLocationId ? getQueryParameter(to, 'customer_code') : customerCodeJWT();
  const connectionType = queryLocationId ? getQueryParameter(to, 'connection_type') : connectionTypeJWT();

  const headerData = {
    mac: storedMac,
    nas_id: nasId,
    location_id: locationId,
    customer_code: customerCode,
    connection_type: connectionType,
  };

  if (to.query.token) {
    headerData.token = to.query.token;
    headerData.is_impersonating = true;
  }
  return store.dispatch(ACTION_BLANK_SESSION, headerData);
}

async function checkCurrentPlan(to, from, next) {
  await next(!store.state.plan.currentPlan ? { path: '/' } : true);
}

async function onLogin(to, from, next) {
  if (store.state.siteInfo && store.state.siteInfo.isNoUserJourneyEnabled) {
    await next({ path: '/' });

    if (to.name === 'login') {
      await store.dispatch('ui/stopNavigation');
    }
  } else {
    await next();
  }
}

const router = new Router({
  routes: [
    {
      path: '/',
      component: () => import(/* webpackChunkName: "landing" */ '@/pages/Container/Container.vue'),
      beforeEnter: setup,
      children: [
        {
          path: '',
          name: 'landing',
          component: () => import(/* webpackChunkName: "landing" */ '@/pages/Landing/Landing.vue'),
          beforeEnter: beforeEnterLanding,
          meta: {
            showLogin: true,
          },
        },
        {
          path: 'select-plan',
          name: 'select-plan',
          component: () => import(/* webpackChunkName: "landing" */ '@/pages/SelectPlan/SelectPlan.vue'),
          meta: {
            showLogin: false,
            saveFrom: true,
          },
        },
        {
          path: 'select-plan-anchor',
          name: 'select-plan-anchor',
          component: () => import(/* webpackChunkName: "landing" */ '@/pages/SelectPlan/SelectPlanAnchor.vue'),
          meta: {
            showLogin: false,
          },
        },
        {
          path: 'select-plan-email-verification',
          name: 'select-plan-email-verification',
          component: () => import(/* webpackChunkName: "landing" */ '@/pages/SelectPlan/SelectPlanEmailVerification.vue'),
          meta: {
            showLogin: false,
          },
        },
        {
          path: 'chosen-plan',
          name: 'chosen-plan',
          component: () => import(/* webpackChunkName: "landing" */ '@/pages/ChosenPlan/ChosenPlan.vue'),
          meta: {
            showLogin: false,
          },
          beforeEnter: checkCurrentPlan,
        },
        {
          path: 'login',
          name: 'login',
          component: () => import(/* webpackChunkName: "landing" */ '@/pages/Login/Login.vue'),
          beforeEnter: onLogin,
          meta: {
            showLogin: false,
          },
        },
        {
          path: 'stripe-return',
          name: 'stripe-return',
          component: () => import(/* webpackChunkName: "landing" */ '@/components/stripe-payment/PaymentRedirect.vue'),
          meta: {
            showLogin: false,
          },
        },
        {
          path: 'mfa',
          name: 'mfa',
          // TODO change path to pages
          component: () => import(/* webpackChunkName: "landing" */ '@/components/login/MFA/MFA.vue'),
          // TODO figure out if onLogin needed
          // beforeEnter: onLogin,
          meta: {
            showLogin: false,
          },
        },
        {
          path: 'password-recovery',
          name: 'password-recovery',
          component: () => import(/* webpackChunkName: "landing" */ '@/pages/PasswordRecovery/PasswordRecovery.vue'),
          meta: {
            showLogin: false,
          },
        },
        {
          path: 'sms-password-recovery',
          name: 'sms-password-recovery',
          component: () => import(/* webpackChunkName: "landing" */ '@/pages/SmsPasswordRecovery/SmsPasswordRecovery.vue'),
          meta: {
            showLogin: false,
          },
        },
        {
          path: 'password-reset',
          name: 'password-reset',
          component: () => import(/* webpackChunkName: "landing" */ '@/pages/PasswordReset/PasswordReset.vue'),
          meta: {
            showLogin: false,
          },
          props: ({ query }) => ({
            code: query.code,
          }),
        },
        {
          path: 'http-pap-connection',
          name: 'http-pap-connection',
          component: () => import(/* webpackChunkName: "landing" */ '@/pages/HttpPapConnection/HttpPapConnection.vue'),
          meta: {
            showLogin: false,
          },
          props: ({ query }) => ({
            redirectTo: query.to,
          }),
        },
      ],
    },
    {
      path: '/stardust/auth/login',
      name: 'landing-login',
    },
    {
      path: '/error',
      name: 'error',
      component: () => import(/* webpackChunkName: "error" */ '@/pages/Error/Error.vue'),
    },
    {
      path: '/denied-portal',
      name: 'denied-portal',
      component: () => import(/* webpackChunkName: "denied" */ '@/pages/AccessDeniedPortal/AccessDeniedPortal.vue'),
    },
    {
      path: '/denied',
      name: 'denied',
      component: () => import(/* webpackChunkName: "denied" */ '@/pages/AccessDenied/AccessDenied.vue'),
    },
    {
      path: '/not-connected',
      name: 'not-connected',
      component: () => import(/* webpackChunkName: "not-connected" */ '@/pages/Error/NotConnected.vue'),
      beforeEnter: setupOutOfNetwork,
    },
    {
      path: '/portal-not-found',
      name: 'portal-not-found',
      component: () => import(/* webpackChunkName: "portal-not-found" */ '@/pages/Error/PortalNotFound.vue'),
    },
    {
      path: '/finish-registration',
      name: 'finish-registration',
      component: () => import(/* webpackChunkName: "finish-registration" */ '@/pages/FinishedRegistration/FinishedRegistration.vue'),
    },
    {
      path: '/password-recovery-for-my-account',
      component: () => import(/* webpackChunkName: "password-recovery" */ '@/components/PasswordRecoveryForMyAccount.vue'),
    },
    {
      path: '/password-sms-recovery-for-my-account',
      component: () => import(/* webpackChunkName: "password-recovery" */ '@/components/PasswordSmsRecoveryForMyAccount.vue'),
    },
    {
      path: '/portal',
      component: () => import(/* webpackChunkName: "portal" */ '@/pages/Portal.vue'),
      beforeEnter: setupPortal,
      children: [
        {
          path: '',
          component: () => import(/* webpackChunkName: "portal" */ '@/pages/Dashboard.vue'),
          props: route => ({
            doReload: route.query.reload === 'true',
          }),
        },
        {
          path: 'manage-your-plan',
          component: () => import(/* webpackChunkName: "portal" */ '@/pages/manageYourPlan/ManageYourPlan.vue'),
          beforeEnter: redirectIfNotLoggedInOrIfAnchorFujitsu,
        },
        {
          path: 'manage-your-plan/plans',
          name: 'manage-plans',
          component: () => import(/* webpackChunkName: "portal" */ '@/pages/manageYourPlan/UpgradePlanContainer.vue'),
          beforeEnter: redirectIfNotLoggedInOrIfAnchorFujitsu,
        },
        {
          path: 'manage-your-plan/home-networking-wizard',
          component: () => import(/* webpackChunkName: "portal" */ '@/pages/manageYourPlan/HomeNetworkingWizard.vue'),
          beforeEnter: redirectIfNotLoggedInOrIfAnchorFujitsu,
        },
        {
          path: 'manage-your-plan/cancel',
          component: () => import(/* webpackChunkName: "portal" */ '@/pages/manageYourPlan/CancelPlan.vue'),
          beforeEnter: redirectIfNotLoggedInOrIfAnchorFujitsu,
        },
        {
          path: 'billing-information',
          component: () => import(/* webpackChunkName: "portal" */ '@/pages/billingInformation/BillingInformation.vue'),
          beforeEnter: redirectIfNotLoggedInOrIfAnchorFujitsu,
        },
        {
          path: 'billing-information/:subscriptionId',
          component: () => import(/* webpackChunkName: "portal" */ '@/pages/billingInformation/SubscriptionDetailed.vue'),
          beforeEnter: redirectIfNotLoggedInOrIfAnchorFujitsu,
        },
        {
          path: 'device-management',
          component: () => import(/* webpackChunkName: "portal" */ '@/pages/deviceManagement/DeviceManagement.vue'),
          beforeEnter: redirectIfNotLoggedInOrIfAnchorFujitsu,
        },
        {
          path: 'device-management/add-device',
          component: () => import(/* webpackChunkName: "portal" */ '@/pages/deviceManagement/AddDevice.vue'),
          beforeEnter: redirectIfNotLoggedInOrIfAnchorFujitsu,
        },
        {
          path: 'device-management/add-connection',
          component: () => import(/* webpackChunkName: "portal" */ '@/pages/deviceManagement/AddConnection.vue'),
          beforeEnter: redirectIfNotLoggedInOrIfAnchorFujitsu,
        },
        {
          path: 'device-management/edit-device/:id',
          component: () => import(/* webpackChunkName: "portal" */ '@/pages/deviceManagement/EditDevice.vue'),
          beforeEnter: redirectIfNotLoggedInOrIfAnchorFujitsu,
        },
        {
          path: 'preferences-and-privacy',
          component: () => import(/* webpackChunkName: "portal" */ '@/pages/PreferencesAndPrivacy/PreferencesAndPrivacy.vue'),
          beforeEnter: redirectIfNotLoggedInOrIfAnchorFujitsu,
        },
        {
          path: 'my-account',
          component: () => import(/* webpackChunkName: "portal" */ '@/pages/my-account/MyAccount.vue'),
          beforeEnter: redirectIfNotLoggedInOrIfAnchorFujitsu,
        },
        {
          path: 'my-account/edit',
          component: () => import(/* webpackChunkName: "portal" */ '@/pages/my-account/EditAccount.vue'),
          beforeEnter: redirectIfNotLoggedInOrIfAnchorFujitsu,
        },
        {
          path: 'my-account/default-payment-method',
          component: () => import(/* webpackChunkName: "portal" */ '@/pages/billingInformation/DefaultPaymentMethod.vue'),
          beforeEnter: redirectIfNotLoggedInOrIfAnchorFujitsu,
        },
      ],
    },
    {
      path: '/old',
      component: () => import(/* webpackChunkName: "main" */ '@/components/Container.vue'),
      beforeEnter: setup,
      children: [
        {
          path: 'activate',
          name: 'activate',
          component: () => import(/* webpackChunkName: "main" */ '@/components/Activate.vue'),
          beforeEnter: async (to, from, next) => next({ path: '/' }),
        },
        {
          path: 'activate-account',
          name: 'activate-account',
          component: () => import(/* webpackChunkName: "main" */ '@/components/ActivateAccount.vue'),
        },
        {
          path: 'activate-by-email',
          name: 'activate-account-by-email',
          component: () => import(/* webpackChunkName: "main" */ '@/pages/ActivateByEmail.vue'),
        },
        {
          path: 'verify',
          name: 'verify',
          component: () => import(/* webpackChunkName: "main" */ '@/components/Verify.vue'),
          beforeEnter: checkIfHasZoneData,
        },
      ],
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  },
});

async function prepareContext(to, from, next) {
  await store.dispatch('ui/startNavigation');
  store.state.siteInfo.isOutsideNetwork = JSON.parse(localStorage.getItem('isOutsideNetwork'));
  if (userIdJWT() && !to.query.redirect && to.name === 'landing-login') {
    return next({ path: '/portal' });
  }

  try {
    if (store.state.siteInfo.locationID === null || isExpiredJWT()) {
      await ensureSession(to);

      await store.dispatch('siteInfo/fetchSiteInfo', { to, from, next });
      if (to.name === 'landing-login') {
        if (to.query.redirect) {
          await next({ path: `/${to.query.redirect}` });
          return;
        }
        await next({ path: '/' });
        return;
      }
    }
    await next();
  } catch (e) {
    console.error(e);
    await next({ path: '/error' });
  }
  try {
    Sentry.configureScope((scope) => {
      scope.setTag('mac', store.state.siteInfo.mac || 'unknown');
    });
  } catch (err) {
    console.warn('Failed to assign mac address to sentry tag', err);
  }
  return Promise.resolve();
}

router.beforeEach(async (to, from, next) => {
  if (to.meta?.saveFrom) {
    to.meta.from = from;
  }
  if (isExpiredJWT() && store.state.siteInfo.isLoggedIn) {
    location.hash = '/login';
  }
  await prepareContext(to, from, next);

  if (routesToLog.includes(to.path)) {
    let token;
    if (localStorage.getItem('Token')) {
      token = JSON.parse(atob(localStorage.getItem('Token').split('.')[1]));
    } else token = 'Token is empty';
    // Log the route change and additional info using Datadog Browser Logs
    const logMessage = {
      message: `mac ${store.state.siteInfo.mac} navigated from "${from.path}" to "${to.path}" (oops page)`,
      ...((store.state.siteInfo.user && store.state.siteInfo.user.stripeUserInfo)
        && { bfAccountId: store.state.siteInfo.user.stripeUserInfo.customerId }),
      mac: store.state.siteInfo.mac,
      token,
      from: from.path,
      to: to.path,
    };

    window.DD_LOGS.onReady(() => {
      window.DD_LOGS.logger.log(logMessage);
    });
  }

  next();
});

router.afterEach(async () => {
  // Meta Pixel 'PageView' tracking event
  if (typeof window.fbq === 'function') {
    window.fbq('track', 'PageView');
  }
  await store.dispatch('ui/stopNavigation');
});

export default router;
